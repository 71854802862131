<template>
    <Kanban 
        :taskType="taskType"
        :pageConfig="pageConfig">
        <slot />
    </Kanban>
</template>

<script>
import Kanban from '../../components/Kanban/KanbanPageSwitch.vue'
import viewProps from '../../mixins/viewProps.js'
import '../../assets/main.scss'
export default {
    mixins: [viewProps],
    components: {
        Kanban
    }
}
</script>