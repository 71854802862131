<template>
    <component 
        :is="pageWidget" 
        :implementId="implementId"
        :implementType="implementType"
        :formParams="formParams"
        :queryParams="queryParams"
        :taskType="taskType"
        :extendDrawer="extendDrawer"
        :pageConfig="pageConfig">
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        pageConfig: {
            type: Object,
            default: () => null
        },
        implementId: {
            type: [String, Number],
            default: null
        },
        implementType: {
            type: String,
            default: ''
        },
        formParams: { // Заполнитель данных в форме по умолчанию
            type: Object,
            default: () => {}
        },
        queryParams: {
            type: Object,
            default: () => null
        },
        taskType: {
            type: String,
            default: 'task'
        },
        extendDrawer: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        pageWidget() {
            if(this.isMobile) {
                return () => import('./index.vue')
            } else {
                return () => import('./KanbanListDesctop.vue')
            }
        }
    }
}
</script>